*{
  box-sizing:border-box;  
}
.wrapperProfile{
    background-color:  #ffffff;
    height: calc(100vh - 79.6px);
    margin-top: 50px;
    .back-profile{
      height: 100%;
      display: flex;
    }
    
.profile-container{
 display: flex;
 width: 70%;
 justify-content: center;
 margin: auto;
 border: 2px solid #cccccc; 
 border-radius: 10px;
 background: #ffffff;
 box-shadow: 3px 3px 5px 6px 	#09679B;
  .earthIdscore{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.score-detail{
  font-size: 20px;
  font-weight: 500;
  background:#5DBCD2;
  color: #ffffff;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  text-align: center;
  padding-top: 30px;
}
.scoreContent{
  font-family: sans-serif;
  font-size: 18px;
}
h4{
  color:black;
  font-family: sans-serif;
}
.details-block{
  margin-bottom: 14px;
  border: 1px solid #BABBBD;
  width: 318px;
  border-radius: 25px;
  padding: 10px;
  outline: none;
}
.title{
  font-size: 20px;
}
img{
width: 93px;
height: 93px;
border: 1px solid black;
border-radius: 50%;
}
.profile-detail{
 padding: 50px;
}
.inner-profile{
 display: flex;
 justify-content: center;
}
.profile-info{
 margin-left: 10px;
}
.profile-pic{
 border: 1px solid #DEE2E3;
 padding: 30px;
 margin-right: 30px;
 background-color: #DEE2E3;
}
input{
 width: 220px;
 padding: 10px;
 border-width: none;
 border-radius: 10px;
 outline: none;
 border: 1px solid darkgrey;
}
}